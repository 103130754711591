import service from "@/api/service";
import { GetResponse } from "@/api/estimate-point/response";
import { GetRequest } from "@/api/estimate-point/request";

/**
 * ポイント試算APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/estimate-point", getRequest);
  return response.data as GetResponse;
}
