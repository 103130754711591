import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { TableOptions } from "@/api/request";
import { GetRequest as GetUserRequest } from "@/api/point-user/request";
import { GetRequest as GetDetailRequest } from "@/api/point-detail/request";
import { GetRequest as EstimatePointRequest } from "@/api/estimate-point/request";
import { RegisterRequest } from "@/api/point/request";
import PointUserGet from "@/store/point-user/get";
import PointDetailGet from "@/store/point-detail/get";
import EstimatePointGet from "@/store/estimate-point/get";
import PointRegister from "@/store/point/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";

const enum CalculationType {
  Addition,
  Subtraction
}

const enum NextLabelEnum {
  CONFIRM = "確認",
  SAVE = "保存する"
}

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIFormRow
  }
})
export default class Edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string | null;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ポイント";
  headingSub = "Points";

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "会員ID", value: "id", sortable: false },
    { text: "氏名", value: "name", sortable: false },
    { text: "性別", value: "gender", sortable: false },
    { text: "生年月日", value: "birthday", sortable: false },
    { text: "現在のポイント", value: "point", sortable: false },
    { text: "", value: "space", sortable: false }
  ];

  // ------------
  // 変動値
  // ------------
  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  // 1ページ目の表示かどうか
  isFirstPage = true;

  inputParams = {
    /** 店舗ID */
    shopId: 0,
    /** ユーザID */
    userId: 0,
    /** ご利用金額入力 */
    price: null,
    /** 付与ポイント */
    point: null,
    /** 付与設定全店or支店 */
    commonFlg: null,
    /** 使用ポイント */
    usePoint: null,
    /** 付与ショップID */
    fuyoShopId: 0,
    /** スペシャルデーショップID */
    spShopId: 0,
  } as RegisterRequest;

  //inputFuyoParams: object[] = [];

  inputFuyoParams: object[] = [];
  inputShopFuyo = Array();
  inputShopFuyoData = Array();
  //NEW_DEV-1712 cyber start
  moneyDisplayFlg = false;
  //NEW_DEV-1712 cyber end
  saveBtnEnabledFlg = true;

  //NEW_DEV-1703 cyber start
  defaultHeaderShow = false;
  //NEW_DEV-1703 cyber end
  viewShopName = '';

  @Watch("search")

  calculationType = CalculationType.Addition;

  get loading() {
    return Loading.isLoading;
  }

  get breadCrumbs() {
    return [
      { text: "アピール", disabled: true },
      { text: "ポイント", disabled: true },
      {
        text: "店舗一覧",
        disabled: false,
        to: { name: "points" }
      },
      {
        text: "ポイントカード一覧",
        disabled: false,
        to: {
          name: "points-list",
          params: { shopId: this.shopId },
          query: { shopName: this.viewShopName }
        }
      },
      { text: "編集", disabled: true }
    ];
  }

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    if (this.$route.query.shopId) {
      return this.$route.query.shopId;
    } else {
      return "";
    }
  }

  get pointUser() {
    if (PointUserGet.isSuccess) {
      let point = PointUserGet.getResults.point;
      if (EstimatePointGet.isSuccess) {
        point = EstimatePointGet.getResults.currentUserPointNum;
      }
      const item = {
        id: PointUserGet.getResults.id,
        name: PointUserGet.getResults.name,
        gender: "不明",
        birthday: PointUserGet.getResults.birthday,
        point: point
      };
      switch (PointUserGet.getResults.gender) {
        case 1:
          item.gender = "男性";
          break;
        case 2:
          item.gender = "女性";
          break;
      }
      return [item];
    } else {
      return [];
    }
  }

  get totalCount() {
    return this.pointUser.length;
  }

  get displayFlg() {
    //NEW_DEV-1712 cyber start
    if (PointDetailGet.isSuccess) {
      this.moneyDisplayFlg = (PointDetailGet.getResults.displayFlg === 1);
      return PointDetailGet.getResults.displayFlg === 1;
    } else {
      this.moneyDisplayFlg = false;
      return false;
    }
    //NEW_DEV-1712 cyber end
  }

  get paymentPrice() {
    if (this.inputParams.price) {
      return Number(this.inputParams.price);
    } else {
      return 0;
    }
  }

  get additionalTotalPointNum() {
    let pointNum = 0;
    if (!PointDetailGet.isSuccess) {
      return pointNum;
    }
    // 割合でポイント付与
    if (this.inputFuyoParams.pointType == 2) {
      let percent =
      this.inputFuyoParams.percent == undefined
          ? 0
          : this.inputFuyoParams.percent;

      pointNum += Math.floor(
        (Number(this.inputParams.price) * Number(percent)) / 100
      );
    // 利用料金額に応じてポイント付与
    } else if (this.inputFuyoParams.pointType == 3) {
        pointNum +=
          Math.floor(Number(this.inputParams.price) / this.inputFuyoParams.amountPerPoint) * this.inputFuyoParams.pointsPerAmount;
    }

    const special = PointDetailGet.getResults.special;
    let additionalPointNumCount = this.additionalPointNum

    for (var item in special) {
      if(special[item].bulkFlg == 0){ //全店共通設定
        if(special[0].spFlg == 1){ //スペシャルデーの対象日の場合
          if (special[0].specialPointType == 1) {
            pointNum += special[0].specialPointCount
          } else if (special[0].specialPointType == 2) {
            pointNum = pointNum * special[0].specialPointRate
            // NEW_DEV-1610 cyber start
            if (additionalPointNumCount > 0){
              additionalPointNumCount = additionalPointNumCount * special[0].specialPointRate
            }
          }
        }
      }else{
        if(special[item].shopId == this.inputFuyoParams.shopId) {
          if(special[item].spFlg == 1){ //スペシャルデーの対象日の場合
            if (special[item].specialPointType == 1) {
              pointNum += special[item].specialPointCount
            } else if (special[item].specialPointType == 2) {
              pointNum = pointNum * special[item].specialPointRate
              if (additionalPointNumCount > 0){
                additionalPointNumCount = additionalPointNumCount * special[item].specialPointRate
              }
            // NEW_DEV-1610 cyber end
            }
          }
        }
      }
    }

    return pointNum + this.paymentPointNum + additionalPointNumCount;
  }


  get additionalPointNum() {
    if (this.inputParams.point) {
      if (this.calculationType === CalculationType.Addition) {
        return Number(this.inputParams.point);
      } else {
        return -Number(this.inputParams.point);
      }
    } else {
      return 0;
    }
  }

  get paymentPointNum() {
    return this.inputParams.usePoint && this.inputParams.usePoint > 0 ? -Number(this.inputParams.usePoint) : 0;
  }

  get totalPointNum() {
    if (PointDetailGet.isSuccess) {
      return (
        PointDetailGet.getResults.currentPoint + this.additionalTotalPointNum
      );
    } else {
      return 0;
    }
  }

  // NEW_DEV-1297 cyber start
  get percent() {
    if (PointDetailGet.isSuccess) {
      const fyObj = PointDetailGet.getResults.fuyo;
      for (var item in fyObj) {
        if(fyObj[item].shopId == this.inputFuyoParams.shopId) {
          switch (fyObj[item].pointType) {
            case 2: //割合
              return PointDetailGet.getResults.fuyo[item].percent + "%";
            case 3: //金額ごと
              return PointDetailGet.getResults.fuyo[item].amountPerPoint + '円ごとに' + PointDetailGet.getResults.fuyo[item].pointsPerAmount + 'ポイント';
          }
        }
      }
    } else {
      return "-";
    }
    return "-";
  }
  // NEW_DEV-1297 cyber end
  get specialDayPointNum() {
    if (PointDetailGet.isSuccess) {
      const spObj = PointDetailGet.getResults.special

      for (var item in spObj) {
        if(spObj[item].bulkFlg == 0){ //全店共通設定
          switch (spObj[0].specialPointType) {
            case 1:
              return `${spObj[0].specialPointCount}ポイント`;
            case 2:
              return `${spObj[0].specialPointRate}倍`;
            default:
              return "-";
          }
        }else{ //店舗別
          if(spObj[item].shopId == this.inputFuyoParams.shopId) {
            switch (spObj[item].specialPointType) {
              case 1:
                return `${spObj[item].specialPointCount}ポイント`;
              case 2:
                return `${spObj[item].specialPointRate}倍`;
              default:
                return "-";
            }
          }
        }
      }
    } else {
      return "-";
    }
    return "-";
  }

  get specialDayDate() {
    if (PointDetailGet.isSuccess) {
      const spObj = PointDetailGet.getResults.special

      for (var item in spObj) {
        if(spObj[item].bulkFlg == 0){ //全店共通設定
          return spObj[0].specialTerm;
        }else{ //店舗別
          if(spObj[item].shopId == this.inputFuyoParams.shopId) {
            return spObj[item].specialTerm;
          }
        }
      }
    } else {
      return "";
    }
    return "";
  }

  get calculationIcon() {
    switch (this.calculationType) {
      case CalculationType.Addition:
        return "mdi-plus";
      case CalculationType.Subtraction:
        return "mdi-minus";
      default:
        return "mdi-plus";
    }
  }

  get saveButtonEnable() {
    return (
      (this.inputParams.price ||
        this.inputParams.point ||
        this.inputParams.usePoint) &&
      this.saveBtnEnabledFlg
    );
  }

  get buttonLabel() {
    if (this.isFirstPage) {
      return NextLabelEnum.CONFIRM;
    }
    return NextLabelEnum.SAVE;
  }

  get estimatedPoint() {
    if (EstimatePointGet.isSuccess) {
      return EstimatePointGet.getResults.estimatedPoint;
    } else {
      return 0;
    }
  }

  get estimatedPointCalcByPrice() {
    if (EstimatePointGet.isSuccess) {
      return EstimatePointGet.getResults.estimatedPointCalcByPrice
    } else {
      return 0;
    }
  }

  get spPoint() {
    if (EstimatePointGet.isSuccess) {
      return EstimatePointGet.getResults.estimatedPointAppliedSpDay - EstimatePointGet.getResults.estimatedPointNotAppliedSpDay;
    } else {
      return 0;
    }
  }

  get estimatedUserPointNum() {
    if (EstimatePointGet.isSuccess) {
      return EstimatePointGet.getResults.estimatedUserPointNum;
    } else {
      return 0;
    }
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (!this.shopId) {
      await this.$router.push({ name: "points" });
      await Flash.setErrorNow({
        message: "パラメータが不足しています。",
        showReloadButton: false
      } as ErrorAlert);
      return;
    }
    // NEW_DEV-1435 cyber start
    let getErrorMessage = null as string | null;
    const pointUser = PointUserGet.get(this.createGetUserRequest());
    await Promise.all([pointUser]);
    // QRコード、主店の権限がない場合、支店ページに遷移する
    if (PointUserGet.isSuccess && PointUserGet.getResults.authorityShop != null) {
      await this.$router.push({
        name: "points-edit",
        params: { id: String(this.id) },
        query: {
          shopId: PointUserGet.getResults.authorityShop["id"],
          shopName: PointUserGet.getResults.authorityShop["name"],
          nowDate: Date.parse(new Date())
        }
      });
    }
    const pointDetail = PointDetailGet.get(this.createGetDetailRequest());
    await Promise.all([pointUser, pointDetail]);
    if (!PointUserGet.isSuccess) {
      getErrorMessage = PointUserGet.getMessage;
    }
    if (!PointDetailGet.isSuccess) {
      getErrorMessage = PointDetailGet.getMessage;
    } else {
      this.GetPointfuyoData()
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.inputParams.shopId = Number(this.shopId);
    this.inputParams.userId = Number(this.id);
    // NEW_DEV-1435 cyber end
    //NEW_DEV-1703 cyber start
    let userAgent = window.navigator.userAgent;
    if (userAgent.match(/newup_admin_tool_ios/) || userAgent.match(/newup_admin_tool_android/)) {
      this.defaultHeaderShow = true;
    }
    //NEW_DEV-1703 cyber end

    //デフォルト金額を設定
    this.inputParams.price = this.gerDefaultPrice();

  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await PointUserGet.clearResponse();
    await PointDetailGet.clearResponse();
    await PointRegister.clear();
    await EstimatePointGet.clearResponse();
  }

  goBack() {
    if (this.isFirstPage) {
      this.$router.back();
    } else {
      window.scrollTo(0, 0);
      this.isFirstPage = true;
    }
  }
  // NEW_DEV-1297 cyber start
  private GetPointfuyoData() {
    //NEW_DEV-1712 cyber start
    if (PointDetailGet.getResults.fuyo) {
      this.inputParams.commonFlg = Number(PointDetailGet.getResults.commonFlg);
      for (var item in PointDetailGet.getResults.fuyo) {
        if (PointDetailGet.getResults.fuyo[item].shopId == this.inputParams.shopId) {
          this.inputFuyoParams = PointDetailGet.getResults.fuyo[item];
          this.inputParams.fuyoShopId = PointDetailGet.getResults.fuyo[item].shopId;
          this.inputParams.spShopId = PointDetailGet.getResults.fuyo[item].shopId;
        }
        PointDetailGet.getResults.fuyo[item].selectname = PointDetailGet.getResults.fuyo[item].shopName
      }

      if (this.inputFuyoParams.length === 0) {
        this.inputFuyoParams = PointDetailGet.getResults.fuyo[0];
        this.inputParams.fuyoShopId = PointDetailGet.getResults.fuyo[0].shopId;
        this.inputParams.spShopId = PointDetailGet.getResults.fuyo[0].shopId;
      }
      this.inputShopFuyoData = PointDetailGet.getResults.fuyo;
      this.moneyDisplayFlg = this.percent == "-";

      //表示処理ショップ名
      //Uレジ連携かつクエリパラメータでshopNameが送られてきていない場合はショップ名をセットする
      this.viewShopName = '';
      if (this.$route.query.shopName) {
        this.viewShopName = this.$route.query.shopName;
      } else if(this.$route.query.para1) {
        for (var fuyoData in this.inputShopFuyoData) {
          if(this.inputShopFuyoData[fuyoData]['shopId'] == this.$route.query.shopId) {
            this.viewShopName = this.inputShopFuyoData[fuyoData]['shopName'];
          }
        }
      }

    } else {
      return `設定なし`;
    }
    //NEW_DEV-1712 cyber end
  }
  // NEW_DEV-1297 cyber end
  itemChange() {
    this.inputParams.fuyoShopId = this.inputFuyoParams.shopId
    const spObj = PointDetailGet.getResults.special
    //NEW_DEV-1712 cyber start
    if(this.inputFuyoParams.pointType == 2 || this.inputFuyoParams.pointType == 3){
      this.moneyDisplayFlg = false;
    }else {
      this.moneyDisplayFlg = true;
      this.inputParams.price = null;
    }
    //NEW_DEV-1712 cyber end

    for (var item in spObj) {
      if(spObj[item].bulkFlg == 0){
        this.inputParams.spShopId = spObj[0].shopId
      }else{
        this.inputParams.spShopId = this.inputFuyoParams.shopId
      }
    }
  }

  switchIconCallback() {
    switch (this.calculationType) {
      case CalculationType.Addition:
        this.calculationType = CalculationType.Subtraction;
        break;
      case CalculationType.Subtraction:
        this.calculationType = CalculationType.Addition;
        break;
    }
  }

  async onSave() {
    // ボタン連打防止対応
    if (!this.saveBtnEnabledFlg) return;
    this.saveBtnEnabledFlg = false;

if (this.isFirstPage) {
    await EstimatePointGet.get(this.createEstimatePointRequest());
    if (EstimatePointGet.isSuccess) {
      this.isFirstPage = false;
    } else {
      await Flash.setErrorNow({
        message: EstimatePointGet.getMessage,
        showReloadButton: false
      });
    }
    this.saveBtnEnabledFlg = true;
} else {
    await PointRegister.register(this.createRegisterRequest());
    if (PointRegister.isSuccess) {
      // Uレジから呼ばれた場合はアラートを返す。それ以外は一覧へ戻る
      if(this.checkUregi()) {
          var param;
          switch (this.$route.query.para1) {
            //ポイント利用
            case 'R01':
              param = {_vue_param: "true", para1: this.$route.query.para1, para2: this.id, para3: this.totalPointNum, para4: this.inputParams.usePoint, para5: this.inputParams.usePoint};
              this.nativePost(param);
              break;
            //ポイント付与
            case 'R02':
              param = {_vue_param: "true", para1: this.$route.query.para1, para2: this.id};
              this.nativePost(param);
              break;
          }
      }

      // ポイント付与正常終了時は画面遷移するだけなので「保存」ボタンは非活性のままにする
      await this.redirectWithSuccessAlert(
        "ポイントを保存しました。",
        `/points/${this.shopId}/list`,
        { shopName: this.viewShopName }
      );
    } else {
      this.saveBtnEnabledFlg = true;

      await Flash.setErrorNow({
        message: PointRegister.getMessage,
        showReloadButton: false
      });
    }
}
  }

  private createGetUserRequest() {
    return {
      shopId: Number(this.shopId),
      userId: Number(this.id)
    } as GetUserRequest;
  }

  private createGetDetailRequest() {
    return {
      shopId: Number(this.shopId),
      userId: Number(this.id)
    } as GetDetailRequest;
  }

  private createEstimatePointRequest() {
    const request = { ...this.inputParams } as EstimatePointRequest;
    if (request.point && this.calculationType === CalculationType.Subtraction) {
        request.point = -request.point;
    }
    return request;
  }

  private createRegisterRequest() {
    const request = { ...this.inputParams } as RegisterRequest;
    if (request.point && this.calculationType === CalculationType.Subtraction) {
      request.point = -request.point;
    }
    return request;
  }

  /**
   * クエリパラメータからUレジ連携かどうかを返す
   */
  private checkUregi() {
    if (this.$route.query.para1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * クエリパラメータからUレジ連携かどうかを返す
   */
  private gerDefaultPrice() {
    if (this.checkUregi() && this.$route.query.para4) {
      return this.$route.query.para4;
    } else {
      return null;
    }
  }

  //ネイティブへのアラート処理
  private nativePost(obj) {
  	var jsonString = JSON.stringify(obj);
  	alert(jsonString);
  }

}
