import { Component, Vue } from "vue-property-decorator";
import Flash, { SuccessAlert } from "@/store/common/flash";

@Component
export default class RedirectWithAlert extends Vue {
  /**
   * successAlertを設定し、アラートの表示先にリダイレクトする
   *
   * @param message
   * @param consumePath
   * @param query
   */
  async redirectWithSuccessAlert(
    message: string,
    consumePath: string,
    query: {} | null = null
  ) {
    if (consumePath.length <= 0) {
      return;
    }
    await Flash.setSuccess({
      message: message,
      consumePath: consumePath
    } as SuccessAlert);
    if (this.$router.currentRoute.path === consumePath) {
      this.$router.go(0);
    } else {
      await this.$router.push({ path: consumePath, query: query ? query : {} });
    }
  }
}
